import React, { useState } from "react";

import { withStyles, Checkbox, Typography, Grid } from "@material-ui/core";
import { TextField, FormControlLabel, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import GridContainer from "components/Grid/GridContainer";
import adminStyle from "assets/jss/mdmcolas/admin/adminStyle";
import { AntTab, AntTabs, TabPanel } from "components/Common/Tabs";
import { isNullOrEmpty, upperFirstLetter } from "tools";
import Button from "components/CustomButtons/Button";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import { TpThirdPartyCategory } from "store/MasterValue/MasterValueTypes";
import { TpCustomerNature } from "store/MasterValue/MasterValueTypes";
import { TpGroup } from "store/MasterValue/MasterValueTypes";
import { TpSupplierNature } from "store/MasterValue/MasterValueTypes";
import { TpIndustryCode } from "store/MasterValue/MasterValueTypes";
import { TpPaymentPeriod } from "store/MasterValue/MasterValueTypes";
import { StructTagType } from "store/MasterValue/MasterValueTypes";
import { LocationActivityType, LocationCharacteristicType } from "store/MasterValue/MasterValueTypes";
import { TpFee } from "store/MasterValue/MasterValueTypes";

const MasterValuesDetail = ({ masterValue, setMasterValue, masterValues, masterValueReducer, defaultLang, classes, canSave, handleSave }) => {
  const [tabIdx, setTabIdx] = useState(0);

  const DialogInlineStyleDetail = {
    dialogContent: {
      padding: "0px 10px 0px",
      height: "90%"
    },
    dialogPaper: {
      minHeight: "90vh",
      maxHeight: "90vh"
    }
  };

  var elementToShows = masterValues.find(f => {
    return f.type === masterValue.masterValueType;
  });

  const gridElementsToShow = (column, value) => {
    if (column.cellType) {
      switch (column.cellType) {
        case "checkbox":
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <label className={classes.LabelSize}> {[column.accessor]}</label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <FormControlLabel
                  control={<Checkbox checked={value} onClick={e => setMasterValue({ ...masterValue, [column.accessor]: !value })} margin="none" />}
                />
              </Grid>
            </Grid>
          );
        case "selectorThirdPartyCategories":
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <label className={classes.LabelSize}>{column.accessor}</label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <MasterValueAutocomplete
                  isEditable
                  mvType={TpThirdPartyCategory}
                  selectedValue={value}
                  onValueChange={code => setMasterValue({ ...masterValue, [column.accessor]: code })}
                />
              </Grid>
            </Grid>
          );
        case "selectorPublicNatureCode":
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <label className={classes.LabelSize}>{column.accessor}</label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <MasterValueAutocomplete
                  isEditable
                  mvType={TpCustomerNature}
                  selectedValue={value}
                  onValueChange={code => setMasterValue({ ...masterValue, [column.accessor]: code })}
                />
              </Grid>
            </Grid>
          );
        case "selectorParentGroupCode":
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <label className={classes.LabelSize}>{column.accessor}</label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <MasterValueAutocomplete
                  isEditable
                  mvType={TpGroup}
                  selectedValue={value}
                  onValueChange={code => setMasterValue({ ...masterValue, [column.accessor]: code })}
                />
              </Grid>
            </Grid>
          );
        case "selectorCountryCode":
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <label className={classes.LabelSize}>{column.accessor}</label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <CountryAutocomplete
                  countryCode={value}
                  onValueChange={code => setMasterValue({ ...masterValue, [column.accessor]: code })}
                  defaultLang={defaultLang}
                  isEditable
                />
              </Grid>
            </Grid>
          );
        case "selectorPrivateNatureCode":
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <label className={classes.LabelSize}>{column.accessor}</label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <MasterValueAutocomplete
                  isEditable
                  mvType={TpCustomerNature}
                  selectedValue={value}
                  onValueChange={code => setMasterValue({ ...masterValue, [column.accessor]: code })}
                />
              </Grid>
            </Grid>
          );
        case "selectorSupplierNatureCode":
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <label className={classes.LabelSize}>{column.accessor}</label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <MasterValueAutocomplete
                  isEditable
                  mvType={TpSupplierNature}
                  selectedValue={value}
                  onValueChange={code => setMasterValue({ ...masterValue, [column.accessor]: code })}
                />
              </Grid>
            </Grid>
          );
        case "selectorIndustryCodeType":
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <label className={classes.LabelSize}>{column.accessor}</label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <MasterValueAutocomplete
                  isEditable
                  mvType={TpIndustryCode}
                  selectedValue={value}
                  onValueChange={code => setMasterValue({ ...masterValue, [column.accessor]: code })}
                />
              </Grid>
            </Grid>
          );
        case "selectorPaymentPeriodCode":
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <label className={classes.LabelSize}>{column.accessor}</label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <MasterValueAutocomplete
                  isEditable
                  mvType={TpPaymentPeriod}
                  selectedValue={value}
                  onValueChange={code => setMasterValue({ ...masterValue, [column.accessor]: code })}
                />
              </Grid>
            </Grid>
          );
        case "selectorStructureTagType":
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <label className={classes.LabelSize}>{column.accessor}</label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <MasterValueAutocomplete
                  isEditable
                  mvType={StructTagType}
                  selectedValue={value}
                  onValueChange={code => setMasterValue({ ...masterValue, [column.accessor]: code })}
                />
              </Grid>
            </Grid>
          );
        case "selectorFeeCode":
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <label className={classes.LabelSize}>{column.accessor}</label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <MasterValueAutocomplete
                  isEditable
                  mvType={TpFee}
                  selectedValue={value}
                  onValueChange={code => setMasterValue({ ...masterValue, [column.accessor]: code })}
                />
              </Grid>
            </Grid>
          );
        case "codeCell":
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <label className={classes.LabelSize}>
                  {" "}
                  <Trans>Code</Trans>
                </label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Typography>{value}</Typography>
              </Grid>
            </Grid>
          );
        case "labelCell":
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <label className={classes.LabelSize}>
                  <Trans>Label</Trans>
                </label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Typography>{value}</Typography>
              </Grid>
            </Grid>
          );
          case "selectorLocationActivityType":
            return (
              <Grid container>
                <Grid item xs={6} sm={6} md={6}>
                  <label className={classes.LabelSize}>{column.accessor}</label>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <MasterValueAutocomplete
                    isEditable
                    mvType={LocationActivityType}
                    selectedValue={value}
                    onValueChange={code => setMasterValue({ ...masterValue, [column.accessor]: code })}
                  />
                </Grid>
              </Grid>
            );
          case "selectorLocationCharType":
            return (
              <Grid container>
                <Grid item xs={6} sm={6} md={6}>
                  <label className={classes.LabelSize}>{column.accessor}</label>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <MasterValueAutocomplete
                    isEditable
                    mvType={LocationCharacteristicType}
                    selectedValue={value}
                    onValueChange={code =>
                      {
                        setMasterValue({ ...masterValue, [column.accessor]: code });
                      }
                    }
                    disabled={column.disabled}
                  />
                </Grid>
              </Grid>
            );
          case "numberSelector":
            return (
              <Grid container>
                <Grid item xs={6} sm={6} md={6}>
                  <label className={classes.LabelSize}>{column.accessor}</label>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <TextField
                    value={value}
                    onChange={e => setMasterValue({ ...masterValue, [column.accessor]: isNullOrEmpty(e.target.value) ? null : e.target.value })}
                    margin="none"
                    type="number"
                    min="1"
                    size="small"
                    fullWidth
                    inputProps={{ style: { fontSize: "14px" } }}
                    style={{ fontSize: "14px" }}
                    showErrorTooltip
                  />
                </Grid>
              </Grid>
            );
        default:
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <label className={classes.LabelSize}>{column.accessor}</label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <TextField
                  value={value}
                  onChange={e => setMasterValue({ ...masterValue, [column.accessor]: isNullOrEmpty(e.target.value) ? null : e.target.value })}
                  margin="none"
                  type="text"
                  size="small"
                  fullWidth
                  inputProps={{ style: { fontSize: "14px" } }}
                  style={{ fontSize: "14px" }}
                  showErrorTooltip
                />
              </Grid>
            </Grid>
          );
      }
    }
  };

  var cardRight = (
    <GridContainer>
      {elementToShows &&
        elementToShows.columns.map(r => {
          let valueOfAccessor = masterValue[r.accessor];
          return gridElementsToShow(r, valueOfAccessor);
        })}
    </GridContainer>
  );

  var cardLeft = (
    <GridContainer>
      {masterValue.labels &&
        Object.entries(masterValue.labels)
          .sort((a, b) => a[0].localeCompare(b[0], defaultLang, { ignorePunctuation: true }))
          .map(element => {
            const key = element[0];
            const value = element[1];
            return (
              <Grid container>
                <Grid item xs={6} sm={6} md={6}>
                  <label className={classes.LabelSize}> {upperFirstLetter(key)}</label>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <TextField
                    value={value}
                    onChange={e =>
                      setMasterValue({
                        ...masterValue,
                        labels: { ...masterValue.labels, [key]: isNullOrEmpty(e.target.value) ? null : e.target.value }
                      })
                    }
                    margin="none"
                    type="text"
                    size="small"
                    fullWidth
                    inputProps={{ style: { fontSize: "14px" } }}
                    style={{ fontSize: "14px" }}
                    showErrorTooltip
                  />
                </Grid>
              </Grid>
            );
          })}
    </GridContainer>
  );

  var masterValueContent = (
    <Grid container>
      <Grid item xs={2} sm={2} md={2}>
        <AntTabs
          orientation="vertical"
          variant="scrollable"
          value={tabIdx}
          onChange={(e, value) => setTabIdx(value)}
          aria-label="Vertical tabs supplier"
        >
          <AntTab label="Valeurs" />
          <AntTab label="Traductions" />
        </AntTabs>
      </Grid>
      <Grid item xs={10} sm={10} md={10} style={{ padding: 20 }}>
        <TabPanel value={tabIdx} index={0}>
          {cardRight}
        </TabPanel>
        <TabPanel value={tabIdx} index={1}>
          {cardLeft}
        </TabPanel>
      </Grid>
    </Grid>
  );

  let btnSave = canSave ? (
    <Button onClick={handleSave} color="info">
      <Trans> Save </Trans>
    </Button>
  ) : (
    ""
  );

  return (
    <Dialog
      open={Boolean(masterValue)}
      onClose={() => setMasterValue(null)}
      fullWidth={true}
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
      scroll="paper"
    >
      <DialogTitle>
        <Trans> Master Value Information </Trans>
      </DialogTitle>
      <DialogContent
        style={{
          ...DialogInlineStyleDetail.dialogContent
        }}
        dividers={true}
      >
        {masterValueContent}
      </DialogContent>
      <DialogActions>
        {btnSave}
        <Button onClick={() => setMasterValue(null)} color={btnSave === "" ? "info" : ""}>
          <Trans> Close </Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(adminStyle)(MasterValuesDetail);
