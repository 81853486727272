import { Trans } from "@lingui/macro";
import { Checkbox } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { isArrayNullOrEmpty, isNull } from "../../../tools";

const HistoRoutageCodes = function ({ customerCtx, classes }) {

  var customer = customerCtx.customer;
  var routageCodes = customer.routageCodes;

  ///------------------------------
  /// Crée le contenu (les lignes) de la table-react 
  ///------------------------------
  function convertDataToReactTable() {
    if (isNull(routageCodes)) return [];
    return routageCodes
      .map((prop, key) => {
        return {
          code: prop.code,
          type: prop.type,
          label: prop.label,
          isActive: prop.isActive,
          /* actions: (
            <div key={key}>
              <Button justIcon round simple onClick={() => openCustomerRoutage(prop.code)} color="info" className="like">
                <Assignment />
              </Button>
            </div>
          ) */
        };
      });
  }
  return (
    <Card style={{ ...CardInlineStyle.card }}>
      <CardHeader style={{ ...CardInlineStyle.cardHeader }}>
        <h4>
          <Trans>History</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <ReactTable
          data={convertDataToReactTable()}
          columns={[
            /* {
              Header: <Trans>Actions</Trans>,
              accessor: "actions",
              sortable: false,
              filterable: false
            }, */
            {
              Header: <Trans>Code</Trans>,
              accessor: "code"
            },
            {
              Header: <Trans>Label</Trans>,
              accessor: "label"
            },
            {
              Header: <Trans>Type</Trans>,
              accessor: "type"
            },
            {
              Header: <Trans>Is Active</Trans>,
              accessor: "isActive",
              Cell: row => (
                <Checkbox
                  disabled
                  checked={row.original.isActive}
                />
              )
            },
          ]}
          defaultPageSize={5}
          showPaginationBottom={false}
          showPageSizeOptions={false}
          className="-striped -highlight"
          showFilters={true}
          style={ReactTableStyle.main}
        />
      </CardBody>
    </Card>
  );
};
const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    customerCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].customerCtx : null,
  };
};

export default connect(
  mapStateToProps,
)(withStyles(thirdPartySearchCriteriaStyle)(HistoRoutageCodes));