import React from "react";
import { emptyIfNull } from "tools";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FieldText from "components/Common/FieldText";
import { FormLabel } from "@material-ui/core";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

//images
import altaresImage from "assets/img/externalrepositories/altares.png";
import infolegaleImage from "assets/img/externalrepositories/infolegale.png";

//lingui
import { Trans } from "@lingui/macro";
import Muted from "components/Typography/Muted";
import { format } from "date-fns";

const TabCompanyGeneral = function({ company, setCompany, isEditable }) {
  var isManual = !company.isFromInfolegal && !company.isFromAltares;

  var externalRepository;
  if (company.isFromInfolegal) {
    externalRepository = <img src={infolegaleImage} width="60px" alt="External Respository src" />;
  } else if (company.isFromAltares) {
    externalRepository = <img src={altaresImage} width="40px" alt="External Respository src" />;
  } else {
    externalRepository = <Trans>ManualSource</Trans>;
  }

  const formatAmount = (amount, currencyCode) => {
    const numberWithThousandSeparator = number => {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    if (amount && currencyCode) {
      return `${numberWithThousandSeparator(amount)} ${currencyCode}`;
    }
    return "";
  };

  const formatDate = strDate => {
    if (strDate) {
      const date = new Date(strDate);
      return format(date, "yyyy-MM-dd");
    }

    return "";
  };

  return (
    <GridContainer>
      <GridItem xs={7} sm={7} md={7}>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>General informations</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>RS Colas</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyLongName"
                  value={emptyIfNull(company.longName)}
                  //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>RS Official</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyOriginalLongName"
                  value={emptyIfNull(company.originalLongName)}
                  //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>TradingName</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyTradingName"
                  value={emptyIfNull(company.tradingName)}
                  //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>Company informations</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>LegalFormCode</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyLegalFormCode"
                  value={emptyIfNull(company.legalFormCode)}
                  //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Capital</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                {/* <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyCapital"
                  value={emptyIfNull(company.capital)}
                  //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                /> */}
                <Muted>{formatAmount(company.capital, company.currencyCode)}</Muted>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>RegisterDate</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                {/* <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyRegisterDate"
                  value={emptyIfNull(company.registerDate)}
                  //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                />
                <Muted>{formatDate(company.registerDate)}</Muted>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Turnover</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                              {/*  <FieldText
                                  isEditable={isEditable && isManual}
                                  id="CompanyTurnover"
                                  value={emptyIfNull(company.turnover)}
                              //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                              /> */}
                <Muted>
                  <Muted>{formatAmount(company.turnover, company.currencyCode)}</Muted>
                </Muted>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>NetProfit</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                {/*  <FieldText
                                  isEditable={isEditable && isManual}
                                  id="CompanyNetProfit"
                                  value={emptyIfNull(company.netProfit)}
                              //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                              /> */}
                <Muted>
                  <Muted>{formatAmount(company.netProfit, company.currencyCode)}</Muted>
                </Muted>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>ClosingDate</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                {/* <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyClosingDate"
                  value={emptyIfNull(company.closingDate)}
                  //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                /> */}
                <Muted>{formatDate(company.closingDate)}</Muted>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>WorkForce</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyWorkForce"
                  value={emptyIfNull(company.workForce)}
                  //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>WorkForceRange</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyWorkForceRange"
                  value={emptyIfNull(company.workForceRange)}
                  //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                />
              </GridItem>

              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>DomesticUltimateDUNS</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText isEditable={isEditable && isManual} id="DomesticUltimateDUNS" value={emptyIfNull(company.domesticUltimateDUNS)} />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>DomesticUltimatePrimaryName</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="DomesticUltimatePrimaryName"
                  value={emptyIfNull(company.domesticUltimatePrimaryName)}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>GlobalUltimateDUNS</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText isEditable={isEditable && isManual} id="GlobalUltimateDUNS" value={emptyIfNull(company.globalUltimateDUNS)} />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>GlobalUltimatePrimaryName</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="GlobalUltimatePrimaryName"
                  value={emptyIfNull(company.globalUltimatePrimaryName)}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={5} sm={5} md={5}>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>Legal identifiers</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Source</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                {externalRepository}
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Legal Identifier</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyLegalIdentifier"
                  value={emptyIfNull(company.legalIdentifier)}
                  //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>TaxCode</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyTaxCode"
                  value={emptyIfNull(company.taxCode)}
                  //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Duns</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyDuns"
                  value={emptyIfNull(company.duns)}
                  //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(TabCompanyGeneral);
