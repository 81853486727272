import React, { useState } from "react";
import {
  TextField,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Card,
  CardContent,
  DialogActions,
  IconButton
} from "@material-ui/core";

//lingui
import { Trans } from "@lingui/macro";
import Geocode from "../../services/masterdata/AzureMapsHelper";
import Muted from "../Typography/Muted";
import { LocationSearching, Map } from "@material-ui/icons";
import ShowMap from "../Map/ShowMap";
import { MapPositions } from "../Map/MapPositions";

export default function GpsAutocomplete({ isEditable, source, setSource, latitudeError, longitudeError, showMap }) {
  var coordinates = source.gpsCoordinates;

  const [showCoordinates, setShowCoordinates] = useState(null);
  const [addressesFound, setAddressesFound] = useState([]);
  const [checkLoading, setCheckLoading] = useState(false);
  const [checkMessage, setCheckMessage] = useState(null);

  if (!isEditable) {
    return (
      <Muted>
        <Trans>Latitude</Trans>: {coordinates.latitude} ; <Trans>Longitude</Trans>: {coordinates.longitude}
      </Muted>
    );
  }

  const checkGeocode = () => {
    if (!source.address_City || !source.address_CountryCode) {
      return;
    }

    setCheckLoading(true);
    setCheckMessage("");
    Geocode(source.address_Line1, source.address_Line2, source.address_ZipCode, source.address_City, source.address_CountryCode).then(results => {
      if (results.length > 0) {
        setAddressesFound(results);
      } else {
        setCheckLoading(false);
        setCheckMessage(<Trans>GPS_NoResultFound</Trans>);
      }
    });
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <TextField
          label={<Trans>GPS_Latitude</Trans>}
          style={{ width: "40%" }}
          value={source.gpsCoordinates.latitude ?? ""}
          error={latitudeError}
          showErrorTooltip
          onChange={e => {
            let val = e.target.value.replace(",", ".");
            if (!isNaN(val)) {
              setSource({ ...source, gpsCoordinates: { ...source.gpsCoordinates, latitude: val } });
            }
          }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label={<Trans>GPS_Longitude</Trans>}
          style={{ width: "40%" }}
          value={source.gpsCoordinates.longitude ?? ""}
          error={longitudeError}
          showErrorTooltip
          onChange={e => {
            let val = e.target.value.replace(",", ".");
            if (!isNaN(val)) {
              setSource({ ...source, gpsCoordinates: { ...source.gpsCoordinates, longitude: val } });
            }
          }}
          InputLabelProps={{ shrink: true }}
        />
        {checkLoading ? (
          <CircularProgress />
        ) : (
          <IconButton variant="outlined" onClick={checkGeocode}>
            <LocationSearching />
          </IconButton>
        )}
        {!!showMap && (
          <IconButton variant="outlined" onClick={() => setShowCoordinates(source)}>
            <Map />
          </IconButton>
        )}
      </div>
      {!!checkMessage ? (
        <div>
          <Typography style={{ color: "red" }}>{checkMessage}</Typography>
        </div>
      ) : (
        <></>
      )}
      <AddressSelector
        addresses={addressesFound}
        selectAddress={a => {
          setSource({ ...source, gpsCoordinates: { latitude: a.latitude, longitude: a.longitude } });
          setCheckLoading(false);
          setAddressesFound([]);
        }}
        onAbort={() => {
          setAddressesFound([]);
          setCheckLoading(false);
        }}
      />
      {!!showMap && <AddressMap coordinates={showCoordinates} onClose={() => setShowCoordinates(null)} />}
    </>
  );
}

function AddressSelector({ addresses, selectAddress, onAbort }) {
  if (!addresses || addresses.length === 0) return <></>;

  return (
    <Dialog open={!!addresses}>
      <DialogContent>
        <DialogContentText>
          <Trans>GPS_DifferentAddress</Trans>
          <Grid container spacing={2}>
            {addresses.map(address => {
              return (
                <Grid item>
                  <Card variant="outlined" onClick={() => selectAddress(address)}>
                    <CardContent>
                      {address.line1}
                      <br />
                      {address.line2}
                      <br />
                      {address.zipCode} {address.city}
                      <br />
                      {address.countryCode}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onAbort()} color="info">
          <Trans> Abort </Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AddressMap({ coordinates, onClose }) {
  if (!coordinates || !coordinates.gpsCoordinates || !coordinates.gpsCoordinates.longitude || !coordinates.gpsCoordinates.latitude) return <></>;

  var gps = MapPositions(coordinates.address_CountryCode);
  const map = (
    <ShowMap
      position={gps.position}
      zoom={gps.zoom}
      markers={[
        {
          line1: coordinates.address_Line1,
          line2: coordinates.address_Line2,
          zipCode: coordinates.address_ZipCode,
          city: coordinates.address_City,
          longitude: coordinates.gpsCoordinates.longitude,
          latitude: coordinates.gpsCoordinates.latitude,
          showPopup: true
        }
      ]}
    />
  );

  return (
    <Dialog open={true} maxWidth="lg" fullWidth>
      <DialogContent>
        <DialogContentText>
          <Trans>GPS_Map</Trans>
          <div>{map}</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="info">
          <Trans> Close </Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
