import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Actions from "./store/actions";
import withStyles from "@material-ui/core/styles/withStyles";
import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Breadcrumbs } from "@material-ui/core";
import DialogBox from "components/DialogBox/DialogBox";
import CardError from "components/Card/CardError";
import Button from "components/CustomButtons/Button";
import { Trans } from "@lingui/macro";
import { GetProduct, SendProductNotifications } from "./actions/ProductActions";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import Muted from "../../components/Typography/Muted";
import ProductDetailContent from "./ProductDetailContent";

const ProductDetail = ({ productId, masterValues, defaultLang }) => {
  const initialState = { isLoading: false };
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const [product, setProduct] = useState();
  var [dialogBox, setDialogBox] = useState(null);

  useEffect(() => {
    setState(initialState);
    setProduct();
  }, [productId]);

  if (!productId) {
    return <></>;
  }

  const sendNotifications = () => {
    setState({ ...state, isLoading: true });
    SendProductNotifications(
      productId,
      _ => {
        setState({
          ...state,
          isLoading: false
        });
      },
      e => setState({ ...state, isLoading: false, error: e })
    );
  };

  const closeDetail = confirmClose => {
    //if (!confirmClose) {
    //    setDialogBox({ type: 'yesNo', message: <Trans> ConfirmClose </Trans>, yes: () => closeDetail(true) });
    //}
    //else {
    setDialogBox(null);
    dispatch(Actions.CloseProduct(productId));
    //}
  };

  var dialogTitle = <>{productId}</>;
  var dialogContent = <></>;
  var dialogActions = <></>;
  if (state.isLoading) {
    dialogContent = <CircularProgress />;
  } else if (state.error) {
    dialogContent = <CardError error={state.error} />;
    dialogActions = (
      <DialogActions>
        <Button onClick={() => closeDetail(false)} color="primary">
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    );
  } else if (!product) {
    setState({ ...state, isLoading: true });
    GetProduct(
      productId,
      result => {
        setState({
          ...state,
          isLoading: false
        });
        setProduct(result);
      },
      e => setState({ ...state, isLoading: false, error: e })
    );
  } else {
    dialogContent = (
      <div>
        <ProductDetailContent product={product} setProduct={setProduct} defaultLang={defaultLang} />
      </div>
    );
    dialogActions = (
      <DialogActions>
        <Button onClick={() => sendNotifications(false)}>
          <Trans>Send Notification</Trans>
        </Button>
        <Button onClick={() => closeDetail(false)} color={"info"}>
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    );

    dialogTitle = (
      <span>
        {product.code} ({product.identifier})
      </span>
    );
  }

  var dialog = (
    <Dialog open={true} onClose={() => closeDetail(false)} fullWidth={true} maxWidth="lg" aria-labelledby="form-dialog-title" scroll="paper">
      <DialogTitle id="form-dialog-title">
        <Breadcrumbs aria-label="Breadcrumb">
          <Muted>{dialogTitle}</Muted>
        </Breadcrumbs>
      </DialogTitle>
      <DialogContent
        style={{
          ...DialogInlineStyleDetail.dialogContent
        }}
        dividers={true}
      >
        {dialogContent}
      </DialogContent>
      {dialogActions}
    </Dialog>
  );

  return (
    <>
      {dialog}
      <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />
    </>
  );
};

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "90%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

export default withStyles(structureStyle)(ProductDetail);
