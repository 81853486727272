import { CircularProgress } from "@material-ui/core";
import CardError from "components/Card/CardError";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LoadMasterValues, StructureErt } from "store/MasterValue/MasterValueTypes";
import ItemAutoComplete from "./ItemAutoComplete";
import StructureUp from "module/workflow/components/StructureUeWorkflow/StructureUp";
import StructureUe from "module/workflow/components/StructureUeWorkflow/StructureUe";
import StructureSj from "module/workflow/components/StructureUeWorkflow/StructureSj";
import { LocationActivityType } from "store/MasterValue/MasterValueTypes";

function MasterValueAutocomplete({
  isEditable,
  isRequired,
  mvType,
  mvFilter,
  selectedValue,
  onValueChange,
  label,
  placeHolder,
  prefixCode,
  formatText,
  multiple,
  error,
  size,
  groupBy,
  sortByText,
  maxResultNumber,
  disabled,
  disabledIfEmpty,
  forceLock,
  onForceLockChanged,
  masterValues,
  readOnly
}) {
  const [mvIsLoaded, setMvIsLoaded] = useState("init");
  const [mvError, setMvError] = useState(null);

  useEffect(() => {
    if (mvIsLoaded === "init") {
      setMvIsLoaded("true");
      LoadMasterValues(mvType)
        .then(() => setMvIsLoaded(null))
        .catch(e => {
          setMvIsLoaded("error");
          setMvError(e);
        });
    }
  }, [mvType]);

  if (!!mvError) return <CardError error={mvError} />;
  if (!!mvIsLoaded) return <CircularProgress size={30} />;

  let items = masterValues[mvType];

  if (mvType == StructureErt || mvType == StructureSj || mvType == StructureUe || mvType == StructureUp) {
    items = items.map(i => {
      return { ...i, code: i.identifier, value: i.identifier };
    });
  }
  if (mvFilter) {
    items = items.filter(mvFilter);
  }
  if(mvType == LocationActivityType){
    items = items.sort((a, b) => a.order - b.order);
  }

  return (
    <ItemAutoComplete
      items={items}
      isEditable={isEditable}
      isRequired={isRequired}
      selectedValue={selectedValue}
      onValueChange={onValueChange}
      label={label}
      placeHolder={placeHolder}
      prefixCode={prefixCode}
      formatText={formatText}
      multiple={multiple}
      error={error}
      size={size}
      groupBy={groupBy}
      sortByText={sortByText}
      maxResultNumber={maxResultNumber}
      disabled={disabled || (!!disabledIfEmpty && items.length === 0)}
      forceLock={forceLock}
      onForceLockChanged={onForceLockChanged}
      readOnly={readOnly}
    />
  );
}

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer
  };
};

export default connect(mapStateToProps)(MasterValueAutocomplete);
