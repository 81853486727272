export const TP_COPERNIC_SEARCH_CLEAN = "TP COPERNIC SEARCH CLEAN";
export const TP_COPERNIC_SEARCH_ERROR = "TP COPERNIC SEARCH ERROR";
export const TP_COPERNIC_SEARCH_BEGIN = "TP COPERNIC SEARCH BEGIN";
export const TP_COPERNIC_SEARCH_END = "TP COPERNIC SEARCH END";
export const TP_COPERNIC_SET_SEARCH_MODE = "TP COPERNIC SET_SEARCH_MODE";
export const TP_COPERNIC_SET_SEARCH_CRITERIA = "TP COPERNIC SET_SEARCH_CRITERIA";
export const TP_COPERNIC_SET_SEARCH_COMPLEX_REQUEST = "TP COPERNIC SET_SEARCH_COMPLEX_REQUEST";

export const TP_COPERNIC_COMPANY_CLOSE = "TP COPERNIC COMPANY CLOSE";
export const TP_COPERNIC_COMPANY_ERROR = "TP COPERNIC COMPANY ERROR";
export const TP_COPERNIC_COMPANY_OPEN = "TP COPERNIC COMPANY OPEN";
export const TP_COPERNIC_COMPANY_SAVE = "TP COPERNIC COMPANY SAVE";

export const TP_COPERNIC_PAYMENT_CHAR_CLOSE = "TP COPERNIC PAYMENT_CHAR CLOSE";
export const TP_COPERNIC_PAYMENT_CHAR_EDITVALUE = "TP COPERNIC PAYMENT_CHAR EDIT VALUE";
export const TP_COPERNIC_PAYMENT_CHAR_ERROR = "TP COPERNIC PAYMENT_CHAR ERROR";
export const TP_COPERNIC_PAYMENT_CHAR_OPEN = "TP COPERNIC PAYMENT_CHAR OPEN";
export const TP_COPERNIC_PAYMENT_CHAR_SAVE_BEGIN = "TP COPERNIC PAYMENT_CHAR SAVE BEGIN";
export const TP_COPERNIC_PAYMENT_CHAR_SAVE_END = "TP COPERNIC PAYMENT_CHAR SAVE END";
export const TP_COPERNIC_PAYMENT_CHAR_SIS_BEGIN = "TP COPERNIC PAYMENT_CHAR CHECK SIS BEGIN";
export const TP_COPERNIC_PAYMENT_CHAR_SIS_END = "TP COPERNIC PAYMENT_CHAR CHECK SIS END";

export const TP_COPERNIC_THIRDPARTY_CLOSE = "TP COPERNIC THIRDPARTY CLOSE";
export const TP_COPERNIC_THIRDPARTY_ERROR = "TP COPERNIC THIRDPARTY ERROR";
export const TP_COPERNIC_THIRDPARTY_EDITVALUE = "TP COPERNIC THIRDPARTY EDIT VALUE";
export const TP_COPERNIC_THIRDPARTY_GET_COMPANY = "TP COPERNIC THIRDPARTY GET COMPANY";
export const TP_COPERNIC_THIRDPARTY_OPEN = "TP COPERNIC THIRDPARTY OPEN";
export const TP_COPERNIC_THIRDPARTY_RELOAD = "TP COPERNIC THIRDPARTY RELOAD";
export const TP_COPERNIC_THIRDPARTY_SAVE_BEGIN = "TP COPERNIC THIRDPARTY SAVE BEGIN";
export const TP_COPERNIC_THIRDPARTY_SAVE_END = "TP COPERNIC THIRDPARTY SAVE END";
export const TP_COPERNIC_THIRDPARTY_SAVE_ERROR = "TP COPERNIC THIRDPARTY SAVE ERROR";
export const TP_COPERNIC_THIRDPARTY_READY = "TP COPERNIC THIRDPARTY READY";

export const TP_COPERNIC_THIRDPARTY_CONTACT_ADD = "TP COPERNIC THIRDPARTY CONTACT ADD";
export const TP_COPERNIC_THIRDPARTY_CONTACT_ERR = "TP COPERNIC THIRDPARTY CONTACT ERROR";
export const TP_COPERNIC_THIRDPARTY_CONTACT_GET = "TP COPERNIC THIRDPARTY CONTACT GET ALL";
export const TP_COPERNIC_THIRDPARTY_CONTACT_REM = "TP COPERNIC THIRDPARTY CONTACT REMOVE";
export const TP_COPERNIC_THIRDPARTY_CONTACT_UPD = "TP COPERNIC THIRDPARTY CONTACT UPDATE";
export const TP_COPERNIC_THIRDPARTY_CONTACT_SAVE_BEGIN = "TP COPERNIC THIRDPARTY CONTACT SAVE BEGIN";
export const TP_COPERNIC_THIRDPARTY_CONTACT_SAVE_END = "TP COPERNIC THIRDPARTY CONTACT SAVE END";

export const TP_COPERNIC_ACCOUNT_EDITVALUE = "TP COPERNIC THIRDPARTY ACCOUNT EDIT VALUE";
export const TP_COPERNIC_ACCOUNT_ERROR = "TP COPERNIC THIRDPARTY ACCOUNT ERROR";
export const TP_COPERNIC_ACCOUNT_GET = "TP COPERNIC THIRDPARTY ACCOUNT GET";
export const TP_COPERNIC_ACCOUNT_SAVE_BEGIN = "TP COPERNIC THIRDPARTY ACCOUNT SAVE BEGIN";
export const TP_COPERNIC_ACCOUNT_SAVE_END = "TP COPERNIC THIRDPARTY ACCOUNT SAVE END";

export const TP_COPERNIC_BENEFICIARY_EDITVALUE = "TP COPERNIC THIRDPARTY BENEFICIARY EDIT VALUE";
export const TP_COPERNIC_BENEFICIARY_ERROR = "TP COPERNIC THIRDPARTY BENEFICIARY ERROR";
export const TP_COPERNIC_BENEFICIARY_GET = "TP COPERNIC THIRDPARTY BENEFICIARY GET";
export const TP_COPERNIC_BENEFICIARY_SAVE_BEGIN = "TP COPERNIC THIRDPARTY BENEFICIARY SAVE BEGIN";
export const TP_COPERNIC_BENEFICIARY_SAVE_END = "TP COPERNIC THIRDPARTY BENEFICIARY SAVE END";

export const TP_COPERNIC_BENEFICIARY_THIRDPARTY_GET = "TP COPERNIC THIRDPARTY BENEFICIARY THIRDPARTY GET ALL";

export const TP_COPERNIC_CUSTOMER_EDITVALUE = "TP COPERNIC THIRDPARTY CUSTOMER EDIT VALUE";
export const TP_COPERNIC_CUSTOMER_ERROR = "TP COPERNIC THIRDPARTY CUSTOMER ERROR";
export const TP_COPERNIC_CUSTOMER_GET = "TP COPERNIC THIRDPARTY CUSTOMER GET";
export const TP_COPERNIC_CUSTOMER_SAVE_BEGIN = "TP COPERNIC THIRDPARTY CUSTOMER SAVE BEGIN";
export const TP_COPERNIC_CUSTOMER_SAVE_END = "TP COPERNIC THIRDPARTY CUSTOMER SAVE END";
export const TP_COPERNIC_CUSTOMER_NOTIFICATION_BEGIN = "TP COPERNIC THIRDPARTY CUSTOMER NOTIFICATION BEGIN";
export const TP_COPERNIC_CUSTOMER_NOTIFICATION_END = "TP COPERNIC THIRDPARTY CUSTOMER NOTIFICATION END";
export const TP_COPERNIC_CUSTOMER_NOTIFICATION_ERROR = "TP COPERNIC THIRDPARTY CUSTOMER NOTIFICATION ERROR";

export const TP_COPERNIC_CUSTOMER_SJ_ADD = "TP COPERNIC THIRDPARTY CUSTOMER SJ ADD";
export const TP_COPERNIC_CUSTOMER_SJ_UPD = "TP COPERNIC THIRDPARTY CUSTOMER SJ UPD";
export const TP_COPERNIC_CUSTOMER_SJ_GET = "TP COPERNIC THIRDPARTY CUSTOMER SJ GET ALL";
export const TP_COPERNIC_CUSTOMER_SJ_SAVE_BEGIN = "TP COPERNIC THIRDPARTY CUSTOMER SJ SAVE BEGIN";
export const TP_COPERNIC_CUSTOMER_SJ_SAVE_END = "TP COPERNIC THIRDPARTY CUSTOMER SJ SAVE END";
export const TP_COPERNIC_CUSTOMER_ADDRESSAGE_ROUTAGE_SAVE_BEGIN = "TP COPERNIC THIRDPARTY CUSTOMER ADDRESSAGE ROUTAGE SAVE BEGIN";
export const TP_COPERNIC_CUSTOMER_ADDRESSAGE_ROUTAGE_SAVE_END = "TP COPERNIC THIRDPARTY CUSTOMER ADDRESSAGE ROUTAGE SAVE END";

export const TP_COPERNIC_POINTOFSALE_EDITVALUE = "TP COPERNIC THIRDPARTY POINTOFSALE EDIT VALUE";
export const TP_COPERNIC_POINTOFSALE_ERROR = "TP COPERNIC THIRDPARTY POINTOFSALE ERROR";
export const TP_COPERNIC_POINTOFSALE_GET = "TP COPERNIC THIRDPARTY POINTOFSALE GET";
export const TP_COPERNIC_POINTOFSALE_SAVE_BEGIN = "TP COPERNIC THIRDPARTY POINTOFSALE SAVE BEGIN";
export const TP_COPERNIC_POINTOFSALE_SAVE_END = "TP COPERNIC THIRDPARTY POINTOFSALE SAVE END";

export const TP_COPERNIC_POINTOFSALE_COUPA_GET = "TP COPERNIC THIRDPARTY POINTOFSALE COUPA GET";
export const TP_COPERNIC_POINTOFSALE_COUPA_EDIT = "TP COPERNIC THIRDPARTY POINTOFSALE COUPA EDIT";
export const TP_COPERNIC_POINTOFSALE_COUPA_ERROR = "TP COPERNIC THIRDPARTY POINTOFSALE COUPA ERROR";
export const TP_COPERNIC_POINTOFSALE_COUPA_GROUP_ADD = "TP COPERNIC THIRDPARTY POINTOFSALE COUPA GROUP ADD";
export const TP_COPERNIC_POINTOFSALE_COUPA_SAVE_BEGIN = "TP COPERNIC THIRDPARTY POINTOFSALE COUPA SAVE BEGIN";
export const TP_COPERNIC_POINTOFSALE_COUPA_SAVE_END = "TP COPERNIC THIRDPARTY POINTOFSALE COUPA SAVE END";

export const TP_COPERNIC_SUPPLIER_EDITVALUE = "TP COPERNIC THIRDPARTY SUPPLIER EDIT VALUE";
export const TP_COPERNIC_SUPPLIER_ERROR = "TP COPERNIC THIRDPARTY SUPPLIER ERROR";
export const TP_COPERNIC_SUPPLIER_GET = "TP COPERNIC THIRDPARTY SUPPLIER GET";
export const TP_COPERNIC_SUPPLIER_SAVE_BEGIN = "TP COPERNIC THIRDPARTY SUPPLIER SAVE BEGIN";
export const TP_COPERNIC_SUPPLIER_SAVE_END = "TP COPERNIC THIRDPARTY SUPPLIER SAVE END";
export const TP_COPERNIC_SUPPLIER_NOTIFICATION_BEGIN = "TP COPERNIC THIRDPARTY SUPPLIER NOTIFICATION BEGIN";
export const TP_COPERNIC_SUPPLIER_NOTIFICATION_END = "TP COPERNIC THIRDPARTY SUPPLIER NOTIFICATION END";
export const TP_COPERNIC_SUPPLIER_NOTIFICATION_ERROR = "TP COPERNIC THIRDPARTY SUPPLIER NOTIFICATION ERROR";

export const TP_COPERNIC_SUPPLIER_COUPA_GET = "TP COPERNIC THIRDPARTY SUPPLIER COUPA GET";
export const TP_COPERNIC_SUPPLIER_COUPA_EDIT = "TP COPERNIC THIRDPARTY SUPPLIER COUPA EDIT";
export const TP_COPERNIC_SUPPLIER_COUPA_ERROR = "TP COPERNIC THIRDPARTY SUPPLIER COUPA ERROR";
export const TP_COPERNIC_SUPPLIER_COUPA_GROUP_ADD = "TP COPERNIC THIRDPARTY SUPPLIER COUPA GROUP ADD";
export const TP_COPERNIC_SUPPLIER_COUPA_SAVE_BEGIN = "TP COPERNIC THIRDPARTY SUPPLIER COUPA SAVE BEGIN";
export const TP_COPERNIC_SUPPLIER_COUPA_SAVE_END = "TP COPERNIC THIRDPARTY SUPPLIER COUPA SAVE END";

export const TP_COPERNIC_SUPPLIER_PAYCHAR_GET = "TP COPERNIC THIRDPARTY SUPPLIER PAYCHAR GET ALL";
export const TP_COPERNIC_SUPPLIER_PAYCHAR_UPD = "TP COPERNIC THIRDPARTY SUPPLIER PAYCHAR UPDATE";
export const TP_COPERNIC_SUPPLIER_PAYCHAR_ERR = "TP COPERNIC THIRDPARTY SUPPLIER PAYCHAR ERROR";
export const TP_COPERNIC_SUPPLIER_PAYCHAR_SAVE_BEGIN = "TP COPERNIC THIRDPARTY SUPPLIER PAYCHAR SAVE BEGIN";
export const TP_COPERNIC_SUPPLIER_PAYCHAR_SAVE_END = "TP COPERNIC THIRDPARTY SUPPLIER PAYCHAR SAVE END";

export const TP_COPERNIC_SUPPLIER_POINTOFSALES_GET = "TP COPERNIC THIRDPARTY SUPPLIER POINTOFSALES GET ALL";
export const TP_COPERNIC_SUPPLIER_POINTOFSALES_ERR = "TP COPERNIC THIRDPARTY SUPPLIER POINTOFSALES ERROR";

export const TP_COPERNIC_SUPPLIER_SJ_ADD = "TP COPERNIC THIRDPARTY SUPPLIER SJ ADD";
export const TP_COPERNIC_SUPPLIER_SJ_UPD = "TP COPERNIC THIRDPARTY SUPPLIER SJ UPDATE";
export const TP_COPERNIC_SUPPLIER_SJ_GET = "TP COPERNIC THIRDPARTY SUPPLIER SJ GET ALL";
export const TP_COPERNIC_SUPPLIER_SJ_SAVE_BEGIN = "TP COPERNIC THIRDPARTY SUPPLIER SJ SAVE BEGIN";
export const TP_COPERNIC_SUPPLIER_SJ_SAVE_END = "TP COPERNIC THIRDPARTY SUPPLIER SJ SAVE END";

export const TP_COPERNIC_DUNSMONITORING_BEGIN_ACTION = "TP COPERNIC DUNSMONITORING_BEGIN_ACTION";
export const TP_COPERNIC_DUNSMONITORING_END_ACTION = "TP COPERNIC DUNSMONITORING_END_ACTION";
export const TP_COPERNIC_DUNSMONITORING_GET_ALL = "TP COPERNIC DUNSMONITORING_GET_ALL";
export const TP_COPERNIC_DUNSMONITORING_GET_DETAIL = "TP COPERNIC DUNSMONITORING_GET_DETAIL";
export const TP_COPERNIC_DUNSMONITORING_SAVE_END = "TP COPERNIC DUNSMONITORING_SAVE_END";
export const TP_COPERNIC_DUNSMONITORING_SET_CRITERIAS = "TP COPERNIC DUNSMONITORING_SET_CRITERIAS";

export const TP_COPERNIC_MIGCODE_BEGIN_ACTION = "TP COPERNIC MIGCODE_BEGIN_ACTION";
export const TP_COPERNIC_MIGCODE_END_ACTION = "TP COPERNIC MIGCODE_END_ACTION";
export const TP_COPERNIC_MIGCODE_GET_ALL = "TP COPERNIC MIGCODE_GET_ALL";
export const TP_COPERNIC_MIGCODE_GET_DETAIL = "TP COPERNIC MIGCODE_GET_DETAIL";
export const TP_COPERNIC_MIGCODE_GET_EXISTING = "TP COPERNIC MIGCODE_GET_EXISTING";
export const TP_COPERNIC_MIGCODE_SAVE_END = "TP COPERNIC MIGCODE_SAVE_END";
export const TP_COPERNIC_MIGCODE_SET_CRITERIAS = "TP COPERNIC MIGCODE_SET_CRITERIAS";
