import { ApimGet, ApimMultiplePost, ApimPost } from "components/Common/ApimSender";
import { HasRight } from "services/user/UserHelper";
import * as Actions from "./types";

export function GetCustomer(thirdPartyId) {
  return (dispatch, getState) => {
    ApimGet(
      "api/TpCopernic/Customers/" + thirdPartyId,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_CUSTOMER_GET,
          customer: responseJson,
          isEditable: HasRight("thirdparty_customer.edit"),
          isEditableDemat: HasRight("thirdparty_customer.edit_demat"),
          isEditableSj: HasRight("thirdparty_customer.edit_sj"),
          canAddSj: HasRight("thirdparty_customer.add_sj")
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_CUSTOMER_ERROR,
          error: error
        });
      }
    );
  };
}

export function EditCustomer(customer) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_CUSTOMER_EDITVALUE,
      customer: customer
    });
  };
}

function crupdateCustomer(customer, reloadThirdParty, dispatch) {
  ApimPost(
    "api/TpCopernic/Customers",
    customer,
    responseJson => {
      dispatch({
        type: Actions.TP_COPERNIC_CUSTOMER_SAVE_END,
        successType: reloadThirdParty ? "SUCCESS_RELOAD" : "SUCCESS",
        customer: responseJson
      });
    },
    error => {
      console.error(error);
      dispatch({
        type: Actions.TP_COPERNIC_CUSTOMER_ERROR,
        error: error
      });
    }
  );
}

export function SaveCustomer(customer, thirdParty, reloadThirdParty) {
  return dispatch => {
    dispatch({
      type: Actions.TP_COPERNIC_CUSTOMER_SAVE_BEGIN
    });

    if (thirdParty?.identifier === 0) {
      ApimPost(
        "api/TpCopernic/ThirdParties",
        thirdParty,
        responseJson => {
          customer.thirdPartyId = responseJson.identifier;
          crupdateCustomer(customer, reloadThirdParty, dispatch);
        },
        error => {
          console.error(error);
          dispatch({
            type: Actions.TP_COPERNIC_CUSTOMER_ERROR,
            error: error
          });
        }
      );
    } else {
      crupdateCustomer(customer, reloadThirdParty, dispatch);
    }
  };
}

export function GenerateNotificationCustomer(customer, callbackFn, errorFn) {
  return dispatch => {
    dispatch({
      type: Actions.TP_COPERNIC_CUSTOMER_NOTIFICATION_BEGIN
    });

    ApimPost(
      "api/TpCopernic/Customers/GenerateNotification",
      customer,
      responseJson => {
        callbackFn();
        dispatch({
          type: Actions.TP_COPERNIC_CUSTOMER_NOTIFICATION_END
        });
      },
      error => {
        errorFn(error.response.data);
        dispatch({
          type: Actions.TP_COPERNIC_CUSTOMER_NOTIFICATION_ERROR,
          error: error
        });
      }
    );
  };
}

/// ------------------------------------------------------------------------------------------------------
/// SJ ACTIONS
/// ------------------------------------------------------------------------------------------------------
export function CustomerSjAdd(sj) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_CUSTOMER_SJ_ADD,
      sj: sj
    });
  };
}

export function CustomerSjUpdate(sj) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_CUSTOMER_SJ_UPD,
      sj: sj
    });
  };
}

export function CustomerSjGet(customerId) {
  return dispatch => {
    ApimGet(
      "api/TpCopernic/Customers/" + customerId + "/sjs",
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_CUSTOMER_SJ_GET,
          sjs: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_CUSTOMER_ERROR,
          error: error
        });
      }
    );
  };
}

export function CustomerSjSave(customerId, sjs) {
  return dispatch => {
    dispatch({
      type: Actions.TP_COPERNIC_CUSTOMER_SJ_SAVE_BEGIN
    });

    const url = "api/TpCopernic/Customers/" + customerId + "/sjs";
    var urlData = sjs
      .filter(sj => sj.action !== "NONE")
      .map(sj => {
        return { url: url, data: sj.sj };
      });

    ApimMultiplePost(
      urlData,
      () => {
        ApimGet(
          url,
          sjs => {
            dispatch({
              type: Actions.TP_COPERNIC_CUSTOMER_SJ_SAVE_END,
              sjs: sjs
            });
          },
          e =>
            dispatch({
              type: Actions.TP_COPERNIC_CUSTOMER_ERROR,
              error: e
            })
        );
      },
      e =>
        dispatch({
          type: Actions.TP_COPERNIC_CUSTOMER_ERROR,
          error: e
        })
    );
  };
}
export function CustomerAddressageRoutageSave(customerId, addressageRoutageCodes) {
  return dispatch => {
    dispatch({
      type: Actions.TP_COPERNIC_CUSTOMER_ADDRESSAGE_ROUTAGE_SAVE_BEGIN
    });
    ApimPost(
      "api/TpCopernic/Customers/" + customerId + "/addressagesRoutages",
      addressageRoutageCodes,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_CUSTOMER_ADDRESSAGE_ROUTAGE_SAVE_END,
          successType: "SUCCESS",
          routageCodes: responseJson.routageCodes,
          addressageCodes: responseJson.addressageCodes
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_CUSTOMER_ERROR,
          error: error
        });
      }
    );
  }
}

///---------------------------------------------------
/// Get History
///---------------------------------------------------
export function GetCustomerHistory(thirdPartyId, callbackFn, errorFn) {
  ApimGet("api/tpCopernic/Customers/" + thirdPartyId + "/histories", callbackFn, errorFn);
}
