import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Avatar, Checkbox, Chip, CircularProgress, FormControlLabel, IconButton, Paper, Tooltip, withStyles } from "@material-ui/core";
import { Assignment, GetApp, LockOpen, Lock, Error, Done } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import React, { useEffect, useState } from "react";
import ReactTable from "react-table-6";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { RegroupementExport } from "module/structure/actions/StructureActions";

function SearchResultTable({ results, exportStructure, openStructure, defaultLang }) {
  // , openStructure
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  if (!results) {
    return "";
  }
  i18n.activate(defaultLang.toLowerCase());
  var pageSize = 10;
  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");
  if (getPref !== null) {
    pageSize = getPref.Pref.ResultPageSize * 1;
  }

  var items = results.map(r => {
    return {
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          <IconButton onClick={() => openStructure(r)} color="info" className="like">
            <Assignment />
          </IconButton>
        </div>
      ),
      id: r.id,
      identifiant: r.identifiant,
      libelle: r.libelle,
      libelleCourt: r.libelleCourt,
      structureType: r.structureType,
      isActive: r.isActive
    };
  });

  const reactTableColumns = [
    {
      Header: x => {
        return (
          <div>
            <Checkbox
              type="checkbox"
              className="checkbox"
              checked={selectAll}
              onChange={e => {
                var isSelectAll = e.currentTarget.checked;
                setSelectAll(isSelectAll);
                setSelectedIds(
                  isSelectAll
                    ? results.map(tp => {
                        return { type: tp.structureType, id: tp.id };
                      })
                    : []
                );
              }}
            />
          </div>
        );
      },
      accessor: "ChexBox",
      width: 50,
      sortable: false,
      filterable: false,
      Cell: rowInfo => {
        var identifier = rowInfo.original.id;
        var type = rowInfo.original.structureType;
        if (identifier > 0) {
          return (
            <Checkbox
              type="checkbox"
              className="checkbox"
              checked={selectedIds.some(id => id.id === identifier)}
              onChange={e => {
                let ids = selectedIds.some(id => id.id === identifier)
                  ? selectedIds.filter(id => id.id !== identifier)
                  : [...selectedIds, { type: type, id: identifier }];
                setSelectedIds(ids);
              }}
            />
          );
        } else return <FormControlLabel disabled control={<Checkbox value="" />} />;
      }
    },
    {
      accessor: "actions",
      width: 50,
      sortable: false,
      filterable: false
    },
    {
      Header: <Trans>Identifiant</Trans>,
      accessor: "identifiant",
      Cell: rowInfo => {
        var statusIcon = !!rowInfo.original.isActive ? <LockOpen style={{ color: "green" }} /> : <Lock style={{ color: "red" }} />;
        return (
          <div>
            {statusIcon}
            {rowInfo.original.identifiant}
          </div>
        );
      }
    },
    {
      Header: <Trans>Structure_Type</Trans>,
      accessor: "structureType"
    },
    {
      Header: <Trans>Structure_LibelleCourt</Trans>,
      accessor: "libelleCourt"
    },
    {
      Header: <Trans>Name</Trans>,
      accessor: "libelle"
    }
  ];

  var paper = <></>;

  if (selectedIds.length > 0) {
    let groups = groupBy(selectedIds, i => i.type);
    let allIds = selectedIds.map(r => r.id);
    let chips = [];
    for (const [key, value] of Object.entries(groups)) {
      chips.push(<ExportButton type={key} ids={value.map(v => v.id)} exportStructure={exportStructure} />);
    }
    chips.push(<ExportButton type={"Regroupements"} ids={allIds} exportStructure={RegroupementExport} />);
    paper = chips;
  }

  return (
    <>
      <Paper>{paper}</Paper>
      <ReactTable
        data={items}
        columns={reactTableColumns}
        defaultPageSize={pageSize}
        showPaginationBottom={true}
        className="-striped -highlight"
        style={ReactTableStyle.main}
      />
    </>
  );
}

const ExportButton = ({ type, ids, exportStructure }) => {
  const [state, setState] = useState({ status: "idle", error: null });

  useEffect(() => setState({ status: "idle", error: null }), [ids]);

  let title, icon, textColor, avatarColor;
  switch (state.status) {
    case "download":
      title = <Trans>Download_inProgress</Trans>;
      icon = <CircularProgress size={20} />;
      textColor = "#999";
      avatarColor = "#FFF";
      break;
    case "downloadDone":
      title = <Trans>Download_done</Trans>;
      icon = <Done />;
      textColor = "#00CC00";
      avatarColor = "#00CC00";
      break;
    case "error":
      title = state.error;
      icon = <Error />;
      textColor = "#CC0000";
      avatarColor = "#CC0000";
      break;
    default:
      title = <Trans>Structure_Export</Trans>;
      icon = <GetApp />;
      textColor = "#999";
      avatarColor = "#999";
      break;
  }

  return (
    <Tooltip title={title}>
      <Chip
        key={"export_" + type}
        avatar={<Avatar style={{ backgroundColor: avatarColor, color: "#FFF" }}>{icon}</Avatar>}
        label={ids.length + " " + type}
        variant="outlined"
        onClick={() => {
          setState({ status: "download" });
          if (type === "Regroupements") {
            exportStructure(ids, () => setState({ status: "downloadDone" }), error => setState({ status: "error", error: error }));
          } else {
            let labelList = labels(type);
            //let selectedIds = ids;
            exportStructure(
              type,
              { labels: labelList, selectedIds: ids },
              () => setState({ status: "downloadDone" }),
              error => setState({ status: "error", error: error })
            );
          }
        }}
        style={{ borderColor: textColor, color: textColor }}
      />
    </Tooltip>
  );
};

var groupBy = function(xs, f) {
  return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
};

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

var labels = function(type) {
  var listLabel = [];
  switch (type) {
    case "AREA":
    case "CDG":
    case "DIR":
    case "DIV":
      listLabel.push({ code: "Identifier", label: i18n._("Identifier") });
      listLabel.push({ code: "Label", label: i18n._("Label") });
      break;

    case "ERT":
    case "EP":
    case "SJ":
    case "UE":
    case "COMP":
    case "UP": {
      listLabel.push({ code: "ParentNiv1", label: i18n._("ParentNiv1") });
      listLabel.push({ code: "ParentNiv2", label: i18n._("ParentNiv2") });
      listLabel.push({ code: "ParentNiv3", label: i18n._("ParentNiv3") });
      listLabel.push({ code: "ParentNiv4", label: i18n._("ParentNiv4") });
      listLabel.push({ code: "ParentNiv5", label: i18n._("ParentNiv5") });
      if (type === "UP" || type === "EP" || type === "UE" || type === "COMP") {
        listLabel.push({ code: "ParentNiv6", label: i18n._("ParentNiv6") });
        listLabel.push({ code: "ParentNiv7", label: i18n._("ParentNiv7") });
        listLabel.push({ code: "ParentNiv8", label: i18n._("ParentNiv8") });
        listLabel.push({ code: "ERT", label: i18n._("Structure_ERT") });
        listLabel.push({ code: "SJ", label: i18n._("Structure_SJ") });
      }
      if (type !== "UE" && type !== "SJ" && type !== "ERT") listLabel.push({ code: "UE", label: i18n._("Structure_UE") });
      if (type === "SJ") listLabel.push({ code: "ERT", label: i18n._("Structure_ERT") });

      listLabel.push({ code: "Identifier", label: i18n._("Identifier") });

      if (type === "UE" || type === "ERT") listLabel.push({ code: "ShirtLabel", label: i18n._("Short Name") });

      if (type !== "UE") listLabel.push({ code: "Label", label: i18n._("Label") });

      if (type === "SJ") {
        listLabel.push({ code: "RaisonSociale", label: i18n._("Structure_RaisonSociale") });
        listLabel.push({ code: "RaisonSocialeComplet", label: i18n._("Structure_RaisonSocialeComplet") });
      } else if (type === "UE") listLabel.push({ code: "Siret", label: i18n._("Siret") });
      else if (type !== "ERT" && type !== "UP" && type !== "COMP") listLabel.push({ code: "LegalIdentifier", label: i18n._("LegalIdentifier") });

      if (type !== "ERT" && type != "COMP") {
        listLabel.push({ code: "Address_Line1", label: i18n._("Line1") });
        listLabel.push({ code: "Address_Line2", label: i18n._("Line2") });
        listLabel.push({ code: "Address_ZipCode", label: i18n._("Address_ZipCode") });
        listLabel.push({ code: "Commune", label: i18n._("Commune") });
        listLabel.push({ code: "Pays", label: i18n._("Address_Country") });
        listLabel.push({ code: "Province", label: i18n._("Address_County") });
        listLabel.push({ code: "Address_State", label: i18n._("Address_State") });
        listLabel.push({ code: "Longitude", label: i18n._("Longitude") });
        listLabel.push({ code: "Latitude", label: i18n._("Latitude") });
      }
      //Labels spécifique
      if (type === "UE") {
        listLabel.push({ code: "Label", label: i18n._("Label") });
        listLabel.push({ code: "Secteur", label: i18n._("Structure_UE_Secteur") });
        listLabel.push({ code: "CodeHra", label: i18n._("Structure_SJ_CodeHra") });
        listLabel.push({ code: "CodeIris3", label: i18n._("Code Iris3") });
        listLabel.push({ code: "ActivitePrincipale", label: i18n._("Structure_UE_ActivitePrincipale") });
        listLabel.push({ code: "ActivitiesLevel2", label: i18n._("Activity Level 2") });
        listLabel.push({ code: "IsBilan", label: i18n._("Structure_UE_IsBilan") });
        listLabel.push({ code: "CodeCol", label: i18n._("Structure_UE_CodeCol") });
        listLabel.push({ code: "SjLegalEntityId", label: i18n._("Structure_SjLegalEntity") });
      }

      if (type === "COMP") {
        listLabel.push({ code: "SjLegalEntityId", label: i18n._("Structure_SjLegalEntity") });
      }

      if (type === "ERT") {
        listLabel.push({ code: "Codification", label: i18n._("Structure_ERT_Codification_Export") });
        listLabel.push({ code: "SiteInformatique", label: i18n._("Structure_ERT_SiteInformatique_Export") });
        listLabel.push({ code: "Address_CountryCode", label: i18n._("Structure_ERT_Address_CountryCode_Export") });
      }

      if (type === "UP") {
        listLabel.push({ code: "IdReporting", label: i18n._("Structure_UP_IdReporting") });
        listLabel.push({ code: "TypeInstallation", label: i18n._("TypeInstallation") });
        listLabel.push({ code: "ActivitiesLevel2", label: i18n._("Activity Level 2") });
        listLabel.push({ code: "IsMobile", label: i18n._("Structure_UP_IsMobile") });
        listLabel.push({ code: "CodeCol", label: i18n._("Structure_UP_CodeCol") });
        listLabel.push({ code: "Siret", label: i18n._("Legal Identifier") });
      }

      if (type === "EP") {
        listLabel.push({ code: "CodeNaf", label: i18n._("CodeNaf") });
        listLabel.push({ code: "IsSiegeSociete", label: i18n._("IsSiegeSociete") });
        listLabel.push({ code: "Email", label: i18n._("Email") });
        listLabel.push({ code: "NumFax", label: i18n._("NumFax") });
        listLabel.push({ code: "NumTel", label: i18n._("Phone Number") });
        listLabel.push({ code: "UeJadeIdentifiant", label: i18n._("UeJadeIdentifiant") });
        listLabel.push({ code: "UeJadeLibelle", label: i18n._("UeJadeLibelle") });
        listLabel.push({ code: "JadeHierarchy", label: i18n._("JadeHierarchy") });
      }

      if (type === "SJ") {
        listLabel.push({ code: "CodeJdeSepSarl", label: i18n._("Structure_SJ_CodeJdeSepSarl") });
        listLabel.push({ code: "FormeJuridique", label: i18n._("Structure_SJ_FormeJuridique") });
        listLabel.push({ code: "CodeMig", label: i18n._("Structure_CodeMig") });
        listLabel.push({ code: "CodeNaf", label: i18n._("CodeNaf") });
        listLabel.push({ code: "LegalIdentifier", label: i18n._("LegalIdentifier") });
        listLabel.push({ code: "NumTva", label: i18n._("TaxCode") });
        listLabel.push({ code: "CodeHra", label: i18n._("Structure_SJ_CodeHra") });
        listLabel.push({ code: "ShortLabel", label: i18n._("Structure_LibelleCourt") });
        listLabel.push({ code: "CodeJade", label: i18n._("CodeJade") }); //CodeJade
        //ShortLabel
        listLabel.push({ code: "SiteInformatique", label: i18n._("Structure_SJ_SiteInformatique") });
        listLabel.push({ code: "GestionAdministrative", label: i18n._("Structure_SJ_IsGestionAdministrative") });
        listLabel.push({ code: "SiegeFiliale", label: i18n._("Structure_SJ_IsSiegeFiliale") });
        listLabel.push({ code: "IsGestionTechnique", label: i18n._("Structure_SJ_IsGestionTechnique") });
        listLabel.push({ code: "BudgetInvestissement", label: i18n._("BudgetInvestissement") });
        listLabel.push({ code: "IsSuccursale", label: i18n._("Structure_SJ_IsSuccursale") });
        listLabel.push({ code: "CapitalSocial", label: i18n._("Structure_SJ_CapitalSocial") });
        listLabel.push({ code: "Partenaires", label: i18n._("Structure_SJ_Partenaires") });
        listLabel.push({ code: "RCS", label: i18n._("Structure_SJ_RCS") });
        listLabel.push({ code: "HasColasStaff", label: i18n._("Structure_SJ_HasColasStaff") });
      }

      if (type !== "SJ" && type !== "EP") listLabel.push({ code: "NonCopernicCode", label: i18n._("NonCopernicCode") });

      break;
    }
    default:
      break;
  }
  return listLabel;
};

export default withStyles(thirdPartySearchCriteriaStyle)(SearchResultTable);
